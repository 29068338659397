<template>


  <ClientOnly>
    <Teleport v-if="active" to="#app">

    <UiModal
      :title="'New week type'"   
      :close-button-name="close"
      :is-transparent="true"
      :is-auto-height="true"
      :width="'70%'"
      :max-width="'400px'"
      @close="closeModal()"         
      >        
        
        <UiButton
          tag="NuxtLink"       
          v-if="!existsGermination"
          :name="$t($constants.typeFaza[-1])"    
          type="ger float"      
          @click.native="createGerm()"      
          :to="'/diaries/' + data.link + '/edit/week/gnew'"
          />
        

        <UiButton
          tag="NuxtLink"             
          :name="$t($constants.typeFaza[0])"    
          type="veg float"      
          @click.native="createVeg()"      
          :to="'/diaries/' + data.link + '/edit/week/vnew'"
          />
      

        <UiButton
          tag="NuxtLink"             
          :name="$t($constants.typeFaza[1])"    
          type="flo float"      
          @click.native="createFlo()"      
          :to="'/diaries/' + data.link + '/edit/week/fnew'"
          />
 
          <!-- {{ props.data.items_seed }} -->

          <!-- {{ props.data.items_week }} -->
         
        <template
          v-for="(seed) in props.data.items_equip.filter((item) => item.category == 'seed')"          
          >

          <UiButton
            tag="NuxtLink"             
            :name="$t($constants.typeFaza[2]) + ' ' + (seed.item_brand.name ?? '') + ' - ' + ( seed.name ?? seed.sec_name)"    
            type="har float"      
            :disabled="seed.enable_harvest"
            @click.native="createHar()"      
            :to="'/diaries/' + data.link + '/edit/week/h' + seed.ref_id"
            />
           
        </template>
      
      </UiModal>

    </Teleport>
  </ClientOnly>
  
</template>

<script setup>

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  active: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['close'])
   
const existsGermination = computed(() => {
  if(props.data.items_week)
  for (const [wkey, w] of props.data.items_week.entries()) {
    if(w.faza == -1)
      return true;
  }
  return false;
})


// const harvest_seeds = computed(() => {
  // var harvested_id = [];
  // if(props.data.items_week){ 
  //   for(var w of props.data.items_week){
  //     if(w.faza == 2)
  //       harvested_id.push(w.item_harvest.item_product.id)
  //   }
  // }
  // var non_harvested = [];
  // for(var s of props.data.items_seed){
  //   if(harvested_id.indexOf(s.id) < 0)
  //     non_harvested.push(s)
  // }
  // return non_harvested;
// })

const closeModal = () => {
  emits('close');
}

const createGerm = () => {
  closeModal();
}

const createVeg = () => {
  closeModal();
}

const createFlo = () => {
  closeModal();
}

const createHar = () => {
  closeModal();
}


</script>

<style scoped>
 
  

</style>
